<ag-shr-modal-window
  #mgaModalRef
  [width]="700"
  [height]="'auto'"
  [title]="'MGA'"
  [inProgress]="inProgress$ | async"
  (onSaveClick)="handleSaveMga($event)"
  [onCloseModal]="handleClosePopup"
>
  <dx-form
    #mgaFormRef
    [visible]="mgaModalRef?.popupComponent?.visible ?? false"
    [(formData)]="mgaFormData"
    [colCount]="12"
    labelMode="floating"
  >
    <dxi-item
      [colSpan]="6"
      [dataField]="AgentKeys.p_mga_id"
      [label]="{ text: 'MGA' }"
      [editorType]="'dxSelectBox'"
      [editorOptions]="{
        dataSource: filteredMGAs$ | async,
        displayExpr: 'name',
        valueExpr: 'agency_id',
        searchEnabled: true,
        showClearButton: true,
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgentKeys.p_agency_id"
      [colSpan]="6"
      [label]="{ text: 'Agency' }"
      [editorType]="'dxSelectBox'"
      [editorOptions]="{
        dataSource: filteredAgencies$ | async,
        displayExpr: 'name',
        valueExpr: 'agency_id',
        searchEnabled: true,
        showClearButton: true
      }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgentKeys.is_manager"
      [label]="{ text: 'Is Team Lead?' }"
      [alignItemLabels]="'left'"
      [colSpan]="6"
      editorType="dxSwitch"
      [editorOptions]="{ switchedOffText: 'false', switchedOnText: 'true' }"
    ></dxi-item>

    <dxi-item
      [dataField]="AgentKeys.is_credited"
      [label]="{ text: 'Credited' }"
      [alignItemLabels]="'left'"
      [colSpan]="6"
      editorType="dxSwitch"
      [editorOptions]="{ switchedOffText: 'false', switchedOnText: 'true' }"
    ></dxi-item>

    <dxi-item [dataField]="AgentKeys.upline" [colSpan]="6" [label]="{ text: 'Upline' }"></dxi-item>

    <dxi-item
      [dataField]="AgentKeys.manager_id"
      [colSpan]="6"
      [label]="{ text: 'Team Leader' }"
      [editorType]="'dxSelectBox'"
      [editorOptions]="{
        items: filteredManagers$ | async,
        displayExpr: 'p_agent_name',
        valueExpr: 'p_agent_id',
        showClearButton: true,
      }"
    >
    </dxi-item>
  </dx-form>
</ag-shr-modal-window>
