import { Pipe, PipeTransform } from '@angular/core';
import { AgentKeys } from '@ag-common-lib/public-api';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { getAgentFullName } from 'ag-common-svc/lib/services/agent.service/agent-service-utils';

@Pipe({
  name: 'mgaTeamLeadDescription',
})
export class MgaTeamLeadDescriptionPipe implements PipeTransform {
  constructor(private agentElasticSearchService: AgentElasticSearchService) {}

  async transform(value: string): Promise<string> {
    if (!value) {
      return Promise.resolve('');
    }
    const agents = await this.agentElasticSearchService.getFromElastic({
      filter: [AgentKeys.p_agent_id, DxFilterOperators.equal, value],
    });
    return getAgentFullName(agents.data[0]);
  }
}
