import { Component, EventEmitter, HostBinding, OnInit, Output, ViewChild } from '@angular/core';
import {
  AttendeeKeys,
  BrokenRule,
  ChangeLogsHistoryType,
  Constants,
  EmailAddressKeys,
  Entity,
  EntityPermissionActivityKeys,
  LookupKeys,
  RegistrantKeys,
  RegistrantModelKeys,
} from 'ag-common-lib/public-api';
import { DxTabPanelComponent, DxValidationSummaryComponent } from 'devextreme-angular';
import { AttendeeDetailsModalService } from './attendee-details-modal.service';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { BehaviorSubject, firstValueFrom, map, Observable, shareReplay, Subject, window } from 'rxjs';
import { AttendeeDetailsModalSection } from './attendee-details-modal.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AttendeeExcursionsService } from './attendee-excursions/attendee-excursions.service';
import { validateSingleDxGroup } from 'ag-common-svc/lib/utils/validation';
import { AttendeesEmailsService } from '../../../attendees-emails.service';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';
import { ConferenceRegistrationEmailCampaignService } from 'ag-common-svc/lib/services/email-campaigns/conference-registration-email-campaign.service';
import { ChangesHistoryGridComponent } from 'ag-common-svc/lib/components/changes-history-grid/changes-history-grid.component';

@UntilDestroy()
@Component({
  selector: 'ag-crm-attendee-details-modal',
  templateUrl: './attendee-details-modal.component.html',
  styleUrls: ['./attendee-details-modal.component.scss'],
  providers: [AttendeeExcursionsService, ConferenceRegistrationEmailCampaignService, AttendeesEmailsService],
})
export class AttendeeDetailsModalComponent implements OnInit {
  @HostBinding('class') className = 'attendee-details-modal';
  @ViewChild('tabsRef', { static: false }) attendeeTabsComponent: DxTabPanelComponent;
  @ViewChild('attendeeFormModalRef', { static: true }) attendeeFormModalComponent: ModalWindowComponent;
  @ViewChild('tShirtSizesValidationSummaryRef', { static: false })
  tShirtSizesValidationSummary!: DxValidationSummaryComponent;
  @ViewChild('attendeeDetailsChangeHistoryRef', { static: false })
  attendeeDetailsChangeHistoryComponent: ChangesHistoryGridComponent;

  @Output() onHidden = new EventEmitter();

  hasFormChanges$ = this.attendeesDetailsModalService.hasFormChanges$;
  validationBrokenRules$ = new BehaviorSubject(null);
  eventName$ = this.attendeesDetailsModalService.eventName$;
  caption = 'Attendee Details';
  eventNameTitleMask = this.caption + Constants.EVENT_NAME_TITLE;
  isHeaderPinned = true;
  isPopupMinimized = false;
  attendee$ = this.attendeesDetailsModalService.attendee$;

  protected mediaPathPrefix: string;
  protected attendeeSections$: Observable<any[]> = this.attendeesDetailsModalService.attendeeSections$;
  protected isDataLoading$: Observable<boolean> = this.attendeesDetailsModalService.isDataLoading$;
  protected formData$ = this.attendeesDetailsModalService.formData$;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly EmailAddressKeys = EmailAddressKeys;
  protected readonly RegistrantModelKeys = RegistrantModelKeys;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly inProgress$ = this.attendeesDetailsModalService.inProgress$;
  protected readonly AttendeeDetailsModalSection = AttendeeDetailsModalSection;
  protected readonly hotelReservationValidationGroup = 'hotelReservationValidationGroup';
  protected readonly generalInfoValidationGroup = 'generalInfoValidationGroup';
  protected readonly mobilePhoneValidationGroup = 'mobilePhoneValidationGroup';
  protected readonly billingAddressValidationGroup = 'billingAddressValidationGroup';
  protected readonly shippingAddressValidationGroup = 'shippingAddressValidationGroup';
  protected readonly tShirtSizesValidationGroup = 'tShirtSizesValidationGroup';
  protected readonly primaryEmailValidationGroup = 'primaryEmailValidationGroup';
  protected readonly dietaryConsiderationValidationGroup = 'dietaryConsiderationValidationGroup';
  protected readonly emergencyContactValidationGroup = 'emergencyContactValidationGroup';
  protected readonly emergencyContactRelationshipTypeValidationGroup =
    'emergencyContactRelationshipTypeValidationGroup';
  protected readonly emailTemplates = this.attendeesEmailsService.emailTemplates;
  protected selectedTabIndex = 0;
  protected readonly ChangeLogsHistoryType = ChangeLogsHistoryType;

  private isSubmitted$ = this.attendeesDetailsModalService.wizardState$.pipe(map(({ isSubmitted }) => isSubmitted));
  private _isBillingDifferentFromShippingAddress$ =
    this.attendeesDetailsModalService.isBillingDifferentFromShippingAddress$;

  private _warnings$ = new Subject<BrokenRule<AttendeeDetailsModalSection>[]>();
  protected warnings$ = this._warnings$.asObservable().pipe(shareReplay());
  private _errors$ = new Subject<BrokenRule<AttendeeDetailsModalSection>[]>();
  protected errors$ = this._errors$.asObservable().pipe(shareReplay());

  constructor(
    private attendeesDetailsModalService: AttendeeDetailsModalService,
    private attendeesEmailsService: AttendeesEmailsService,
  ) {}

  ngOnInit(): void {
    this.attendeesDetailsModalService.showModal$.pipe(untilDestroyed(this)).subscribe(selectedTabIndex => {
      this.selectedTabIndex = selectedTabIndex;
      this.attendeeFormModalComponent?.showModal();

      this._errors$.next(null);
      this._warnings$.next(null);

      this.validationBrokenRules$.next(null);
    });
  }

  onContentReady(e) {
    const contentElement = e.component.content();
    contentElement.style.padding = '0';
  }

  protected sendToEmail = async (e: ItemClickEvent) => {
    await this.attendeesEmailsService.sendEmails(e.itemData, [this.attendeesDetailsModalService.attendee$.value]);
  };

  protected handelModalHidden = () => {
    this.attendeesDetailsModalService.setRegistrationData(null);
    this.isHeaderPinned = true;
    this.onHidden.emit();
  };

  protected handleSaveAttendee = async e => {
    const validationResults = await this.validateGroupRules();

    if (validationResults?.errors?.length) {
      return;
    }

    await this.attendeesDetailsModalService.updateRegistrant();
  };

  showModal = this.attendeesDetailsModalService.showModal;

  protected onValidationSummaryItemClick = async (
    brokenRule: BrokenRule<AttendeeDetailsModalSection>,
  ): Promise<void> => {
    const attendeeSections = await firstValueFrom(this.attendeeSections$);
    const index = attendeeSections.findIndex(({ id }) => id === brokenRule?.sectionId);

    this.attendeeTabsComponent.selectedIndex = index;

    setTimeout(() => {
      brokenRule?.editor?.focus();
    }, 0);
  };

  protected handleClosePopup = this.attendeesDetailsModalService.onCancelEdit;

  protected validateGroupRules = async (): Promise<any> => {
    const warnings = [];
    const errors = [];

    const validationGroups = [
      this.generalInfoValidationGroup,
      this.mobilePhoneValidationGroup,
      this.shippingAddressValidationGroup,
      this.tShirtSizesValidationGroup,
      this.dietaryConsiderationValidationGroup,
      this.emergencyContactValidationGroup,
      this.emergencyContactRelationshipTypeValidationGroup,
      this.hotelReservationValidationGroup,
      this.primaryEmailValidationGroup,
    ];
    if (this._isBillingDifferentFromShippingAddress$.value) {
      validationGroups.push(this.billingAddressValidationGroup);
    }

    for (const validationGroup of validationGroups) {
      const validationResults = await validateSingleDxGroup(validationGroup);

      if (validationResults.isValid) {
        continue;
      }

      validationResults?.invalidEditors?.forEach(editor => {
        const editorName = editor?.option('name');
        const validationError = editor?.option('validationError');
        const isFieldChanged = this.checkIsGroupTouched(editorName);

        const brokenRule: BrokenRule<AttendeeDetailsModalSection> = {
          editor,
          label: this.getBrokenRuleLabel(editorName, validationError?.message),
          sectionId: this.getBrokenRuleSectionId(validationGroup),
        };

        isFieldChanged ? errors.push(brokenRule) : warnings.push(brokenRule);
      });
    }

    this._errors$.next(errors);
    this._warnings$.next(warnings);

    return { warnings, errors };
  };

  private checkIsGroupTouched = (editorName: string = '') => {
    const [groupName] = editorName?.split('.');
    const formChangesDetector = this.attendeesDetailsModalService.formChangesDetector;
    const allChanges = formChangesDetector.getAllChanges();

    switch (groupName) {
      case RegistrantKeys.mobilePhone:
      case RegistrantKeys.shippingAddress:
      case RegistrantKeys.billingAddress:
      case RegistrantKeys.emergencyContact:
        return allChanges?.length && allChanges.some(change => `${change}`.startsWith(groupName));

      default:
        return formChangesDetector.checkIsFieldChanged(editorName);
    }
  };

  private getBrokenRuleSectionId(validationGroup: string): AttendeeDetailsModalSection {
    switch (validationGroup) {
      case this.hotelReservationValidationGroup:
        return AttendeeDetailsModalSection.hotelReservation;

      default:
        return AttendeeDetailsModalSection.generalInfo;
    }
  }

  private getBrokenRuleLabel(editorName: string = '', message: string) {
    const [groupName] = editorName?.split('.');

    switch (groupName) {
      case RegistrantKeys.primaryEmailAddress:
        return `Email Addresses: ${message}`;
      case RegistrantKeys.ccEmail:
        return `CC Email: ${message}`;
      case RegistrantKeys.shippingAddress:
        return `Shipping Addresses: ${message}`;
      case RegistrantKeys.billingAddress:
        return `Billing Addresses: ${message}`;
      case RegistrantKeys.mobilePhone:
        return `Phone Number: ${message}`;
      case RegistrantKeys.tShirtSizes:
        return `T-Shirt Sizes: ${message}`;
      case RegistrantKeys.dietaryConsideration:
        return `Dietary Considerations: ${message}`;
      case RegistrantKeys.emergencyContact:
        return `Emergency Contact: ${message}`;

      default:
        return message;
    }
  }

  onTabPanelSelectionChanged(e) {
    const selectedIndex = e.component.option('selectedItem').id;
    if (selectedIndex === AttendeeDetailsModalSection.changeLog) {
      // manually refresh the grid
      this.attendeeDetailsChangeHistoryComponent.changeLogGridComponent.instance.refresh();
    }
  }

  onHeaderPinClick() {
    this.isHeaderPinned = !this.isHeaderPinned;
  }

  minimizePopup() {
    this.isPopupMinimized = !this.isPopupMinimized;
  }

  closePopup() {
    this.isHeaderPinned = true;
  }

  protected readonly window = window;
}
