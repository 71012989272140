import { Injectable } from '@angular/core';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'ag-common-svc/lib/services/agency.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Agency, AGENCY_TYPE, AgencyKeys } from 'ag-common-lib/public-api';
import { set } from 'lodash';
import { map } from 'rxjs/operators';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';

@Injectable()
export class MgaFormService extends BaseFormService<Agent> {
  filteredManagers$ = new BehaviorSubject<Agent[]>([]);
  filteredAgencies$ = new BehaviorSubject<Agency[]>([]);
  filteredMGAs$ = this.agencyService.mgas$;

  agencies$: Observable<Agency[]> = this.agencyService.agencies$;

  formData: Partial<Agent>;

  constructor(
    private agentService: AgentService,
    private toastrService: ToastrService,
    public agencyService: AgencyService,
    private agentElasticSearchService: AgentElasticSearchService,
  ) {
    super();
  }

  getFormData = async (mga?: Partial<Agent>) => {
    const initialData: Partial<Agent> = {
      [AgentKeys.manager_id]: mga?.manager_id ?? null,
      [AgentKeys.p_mga_id]: mga?.p_mga_id ?? null,
      [AgentKeys.p_agency_id]: mga?.p_agency_id ?? null,
      [AgentKeys.upline]: mga?.upline ?? null,
      [AgentKeys.is_credited]: mga?.is_credited ?? false,
      [AgentKeys.is_manager]: mga?.is_manager ?? false,
    };

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        const result = Reflect.set(target, prop, value, receiver);

        if (prevValue !== value) {
          this.formChangesDetector.handleChange(prop, value, prevValue);

          switch (prop) {
            case AgentKeys.p_agency_id:
              set(this.formData, AgentKeys.manager_id, null);
              this.buildManagersList(this.formData[AgentKeys.p_agent_id], value, this.formData[AgentKeys.p_mga_id]);
              break;

            case AgentKeys.p_mga_id:
              set(this.formData, AgentKeys.p_agency_id, null);
              this.getFilteredAgenciesByMga(value);
              break;
          }
        }

        return result;
      },
    });

    return this.formData;
  };

  updateMGA(agetId: string, mga: Partial<Agent>): Promise<Agent> {
    this.startProgress();
    this.formChangesDetector.clear();
    return this.agentService
      .updateAgentFields(agetId, mga)
      .then(response => {
        this.toastrService.success('MGA Information Successfully Updated');
        this.onSuccessfulUpdated(Object.keys(response));
        return response;
      })
      .catch(err => {
        this.toastrService.error('MGA Information Update Failed!');
        throw new Error();
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  async buildManagersList(agentId: string, agencyId: string, mgaId: string): Promise<void> {
    const filteredManagers = [];
    const managers = await this.getManagers(agentId, agencyId, mgaId);

    managers?.forEach(manager => {
      if (`${manager?.[AgentKeys.p_agent_id]}` === `${agentId}`) {
        return;
      }

      filteredManagers.push(
        Object.assign({}, manager, {
          [AgentKeys.p_agent_name]: [manager?.p_agent_first_name, manager?.p_agent_last_name].filter(Boolean).join(' '),
        }),
      );
    });

    this.filteredManagers$.next(filteredManagers);
  }

  private async getManagers(agentId: string, agencyId: string, mgaId: string) {
    const agency = await this.agencyService.getAgencyByAgencyId(agencyId ?? mgaId);

    const agencyLead = agency?.[AgencyKeys.agencyLead];

    if (agencyLead && `${agencyLead}` === `${agentId}`) {
      return this.agentElasticSearchService.getFromElastic({
        filter: [
          [AgentKeys.p_mga_id, DxFilterOperators.equal, agentId],
          [AgentKeys.is_manager, DxFilterOperators.equal, true],
        ],
      }).then(agents => agents.data);
    }

    return this.agentElasticSearchService
      .getFromElastic({
        filter: [[AgentKeys.p_agency_id, DxFilterOperators.equal, agencyId]],
      })
      .then(agents => {
        return agents.data.filter(agent => !!agent[AgentKeys.is_manager] && !!agent[AgentKeys.p_agent_id]);
      });
  }

  getFilteredAgenciesByMga(mgaId: string): void {
    this.agencies$
      .pipe(map(agencies => agencies.filter(agency => this.filterByMGA(agency, mgaId)).sort(this.sortByAgencyType)))
      .subscribe(agenciesByMga => {
        this.filteredAgencies$.next(agenciesByMga ?? []);
      });
  }

  private filterByMGA = (agency: Agency, mgaId: string): boolean => {
    switch (agency[AgencyKeys.agencyType]) {
      case AGENCY_TYPE.MGA:
        return agency[AgencyKeys.agencyId] == mgaId;
      case AGENCY_TYPE.AGENCY:
        return agency[AgencyKeys.mga] == mgaId;
    }
  };

  private sortByAgencyType = (a, b): number =>
    a[AgencyKeys.agencyType] > b[AgencyKeys.agencyType]
      ? -1
      : a[AgencyKeys.agencyType] < b[AgencyKeys.agencyType]
        ? 1
        : 0;
}
