import { Injectable } from '@angular/core';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { format, formatDistanceToNow, isValid, toDate } from 'date-fns';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  shareReplay,
  startWith,
  Subject,
  take,
  tap,
  timer,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  AGENCY_TYPE,
  AgencyKeys,
  AgentKeys,
  BaseModelKeys,
  ConferenceKeys,
  EmailAddressKeys,
  Entity,
  EntityPermissionActivityKeys,
  InviteeStatus,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
} from 'ag-common-lib/public-api';
import { AgencyService, AgentService, AuthService, ConferenceRegistrantsService } from 'ag-common-svc/public-api';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { AttendeeDetailsModalSection } from '../conferences/modals/attendee-details-modal/attendee-details-modal.model';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';

@Injectable()
export class ConferenceRegistrationGridService {
  private _showAttendeeDetailsModal$ = new Subject<{ attendee: Attendee; section: AttendeeDetailsModalSection }>();
  showAttendeeDetailsModal$ = this._showAttendeeDetailsModal$.asObservable();

  private _refreshTrigger$ = new Subject<void>();

  private _lastUpdateDate$ = new BehaviorSubject<Date>(null);
  lastUpdateDate$ = timer(1000, 1000 * 30).pipe(
    mergeMap(() => this._lastUpdateDate$),
    map(date => {
      if (!date) {
        return 'Last Update: Now';
      }

      return `Last Update: ${formatDistanceToNow(date)} ago`;
    }),
  );

  private _conferenceDbId$ = new BehaviorSubject<string>(null);
  conferenceDbId$ = this._conferenceDbId$.asObservable();
  conference$ = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => {
      return this.conferenceService.getDocumentData(conferenceDbId);
    }),
    shareReplay(1),
  );
  embeddedFormEnabled$ = this.conference$.pipe(map(conference => conference?.[ConferenceKeys.embeddedFormEnabled]));

  inProgress$ = new BehaviorSubject<boolean>(false);

  conferencesList$ = combineLatest({
    conferences: this.conferenceService.getList([], { sortField: 'start_date' }).pipe(startWith([])),
    loggedInAgent: this.authService.loggedInAgent$,
    hasEventListPermission: this.hasPermissionPipe.transform(Entity.conferenceList, EntityPermissionActivityKeys.read),
    hasEventLisAdmintPermission: this.hasPermissionPipe.transform(
      Entity.conferenceListAdmin,
      EntityPermissionActivityKeys.read,
    ),
  }).pipe(
    map(({ conferences, loggedInAgent, hasEventListPermission, hasEventLisAdmintPermission }) => {
      if (hasEventListPermission && !hasEventLisAdmintPermission) {
        // show only conferences that user has access to eventList permission only
        const agentDbId = loggedInAgent?.[BaseModelKeys.dbId];
        return conferences?.filter(conference => {
          const createdByAgentDbId = [conference?.[BaseModelKeys.createdByAgentDbId]];
          const permissionConfigurationIds = conference?.[ConferenceKeys.permissionConfigurationIds] ?? [];
          const permissionIds = new Set(createdByAgentDbId.concat(permissionConfigurationIds));
          return permissionIds?.has(agentDbId);
        });
      }
      return conferences;
    }),
    map(conferences => {
      return conferences?.map(conference => {
        const conferenceDbId = conference?.dbId;
        const conferenceName = conference?.event_name;
        const conferenceStartDate = isValid(toDate(conference?.[ConferenceKeys.startDate]))
          ? format(conference?.start_date as Date, "'from' MM-dd-yyyy")
          : null;
        const conferenceEndDate = isValid(toDate(conference?.[ConferenceKeys.endDate]))
          ? format(conference?.end_date as Date, "'to' MM-dd-yyyy")
          : null;

        const conferenceDuration = [conferenceStartDate, conferenceEndDate].filter(Boolean).join(' ');
        const description = `${conferenceName} (${conferenceDuration})`;

        return { conferenceDbId, description };
      });
    }),
    shareReplay(1),
  );

  agencies$ = this.agencyService.getList().pipe(shareReplay(1));
  mgaList$ = this.agencies$.pipe(
    map(agencies => agencies?.filter(agency => agency?.[AgencyKeys.agencyType] === AGENCY_TYPE.MGA)),
  );

  registrants$: Observable<Registrant[]> = combineLatest({
    conferenceDbId: this._conferenceDbId$,
    _refreshTrigger$: this._refreshTrigger$.pipe(startWith(null)),
  }).pipe(
    tap(() => {
      this._lastUpdateDate$.next(new Date());
    }),
    map(({ conferenceDbId }) => conferenceDbId),
    filter(Boolean),
    mergeMap(conferenceDbId => {
      return this.conferenceRegistrantsService
        .getRegistrantsByConferenceId(conferenceDbId, 'last_name')
        .pipe(take(1), shareReplay(1));
    }),
    tap(() => {
      this._lastUpdateDate$.next(new Date());
    }),
    shareReplay(1),
  );

  participatedAttendees$ = combineLatest({ conference: this.conference$, registrants: this.registrants$ }).pipe(
    map(({ registrants, conference }) =>
      registrants
        ?.filter(registrant => {
          const registrantData = registrant?.[RegistrantModelKeys.data];
          const inviteeStatus = registrantData?.[RegistrantKeys.inviteeStatus];
          const inviteeOutcomeStatus = registrantData?.[RegistrantKeys.inviteeOutcomeStatus];

          return (
            inviteeStatus !== InviteeStatus.declined &&
            !new Set([InviteeStatus.cancelled, InviteeStatus.doNotShowUp]).has(inviteeOutcomeStatus)
          );
        })
        .map(registrant => new Attendee(conference, registrant)),
    ),
    map(
      (data = []) =>
        new DataSource({
          paginate: true,
          store: new ArrayStore({ key: BaseModelKeys.dbId, data }),
        }),
    ),
    shareReplay(1),
  );

  declinedRegistrants$ = this.registrants$.pipe(
    map(registrants =>
      registrants?.filter(registrant => {
        const registrantData = registrant?.[RegistrantModelKeys.data];
        const inviteeStatus = registrantData?.[RegistrantKeys.inviteeStatus];
        const inviteeOutcomeStatus = registrantData?.[RegistrantKeys.inviteeOutcomeStatus];

        return (
          inviteeStatus === InviteeStatus.declined ||
          new Set([InviteeStatus.cancelled, InviteeStatus.doNotShowUp]).has(inviteeOutcomeStatus)
        );
      }),
    ),
  );

  assignOwnerList$ = this.conference$.pipe(
    map(conference => conference?.[ConferenceKeys.permissionConfigurationIds]),
    mergeMap(permissionConfigurationIds => {
      return from(this.agentService.getAgentsByAgentIds(permissionConfigurationIds));
    }),
    map(assignOwnerList => {
      assignOwnerList = assignOwnerList.map(item => ({
        ...item,
        [AgentKeys.email_addresses]: item[AgentKeys.email_addresses] || [
          {
            [EmailAddressKeys.address]: item[AgentKeys.p_email],
            [EmailAddressKeys.isLogin]: true,
          },
        ],
      }));
      return new DataSource({
        store: new ArrayStore({
          key: 'dbId',
          data: Array.isArray(assignOwnerList) ? assignOwnerList : [],
        }),
      });
    }),
    shareReplay(1),
  );

  constructor(
    private agencyService: AgencyService,
    private agentService: AgentService,
    private conferenceService: ConferenceService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private hasPermissionPipe: HasPermissionPipe,
  ) {
    this.lastUpdateDate$.subscribe();
  }

  setConferenceDbId = conferenceDbId => {
    this._conferenceDbId$.next(conferenceDbId);
  };

  forceRefresh = () => {
    this._refreshTrigger$.next();
  };

  showAttendeeModal = (attendee: Attendee, section = AttendeeDetailsModalSection.generalInfo) => {
    this._showAttendeeDetailsModal$.next({
      attendee,
      section,
    });
  };

  updateRegistrant(conferenceId: string, registrantId: string, update: Partial<Registrant>): Promise<boolean> {
    this.inProgress$.next(true);
    return this.conferenceRegistrantsService
      .update(conferenceId, registrantId, update)
      .then(() => {
        this.forceRefresh();
        return true;
      })
      .catch(err => {
        this.toastrService.error('Registrant was Not Updated');
        throw err;
      })
      .finally(() => {
        this.inProgress$.next(false);
      });
  }
}
