import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ag-crm-admin-crm',
  templateUrl: './admin-crm.component.html',
  styleUrls: ['./admin-crm.component.scss'],
})
export class AdminCrmComponent {
  @HostBinding('class') className = 'admin-crm';
}
