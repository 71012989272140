import { Inject, Injectable } from '@angular/core';
import { BaseModelKeys, Report } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP, QueryParam, WhereFilterOperandKeys } from '../../public-api';
import { DataService } from './data.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends DataService<Report> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    super.setCollection('reports');
  }

  getResentReportByYear = (year: number) => {
    return this.getList([new QueryParam('year', WhereFilterOperandKeys.equal, year)], {
      sortField: BaseModelKeys.createdDate,
      limit: 1,
    }).pipe(
      map(list => {
        return list?.[0];
      }),
    );
  };
}
