import { Injectable } from '@angular/core';
import { PolicyTransaction } from '@ag-common-lib/lib/models/domain/policy-transaction.model';
import { Agency } from '@ag-common-lib/public-api';
import { AgencyService } from './agency.service';
import * as papaparse from 'papaparse';
import { isMatch } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ImportFileValidatorService {
  agencyToAgencyIdMappingTable: Map<string, Agency> = new Map<string, Agency>();

  constructor(public agencyService: AgencyService) {}

  importPaidFiles = async (files: File[], messagesMonitor: string[]): Promise<PolicyTransaction[]> => {
    const promises: Promise<PolicyTransaction[]>[] = files.map(this.parseFile);

    const transactionsCollections = await Promise.all(promises);
    const transactions = transactionsCollections.flat(1);

    try {
      await this.evaluateImport(transactions, messagesMonitor);
    } catch (error) {
      messagesMonitor.unshift('Stopping import until all records are corrected!');
      throw new Error(error);
    }

    return transactions;
  };

  private parseFile = (file: File): Promise<PolicyTransaction[]> => {
    return new Promise((res, rej) => {
      papaparse.parse(file, {
        header: true,
        worker: true,
        skipEmptyLines: 'greedy',
        complete: function (results) {
          res(results.data);
        },
      });
    });
  };

  // TODO need to refactor
  private evaluateImport(transactions: PolicyTransaction[], messages: string[]): Promise<boolean> {
    let carrierMissingIDCount: number = 0;
    let mgaMissingIDCount: number = 0;
    let agentMissingIDCount: number = 0;
    let incorrectDateFormatCount: number = 0;

    return this.agencyService.getMGAAgencies('name').then(
      agencies => {
        this.agencyToAgencyIdMappingTable = new Map(
          agencies.map(agency => {
            return [agency.agency_id, agency];
          }),
        );

        let missingMGAList: Map<string, string> = new Map<string, string>();

        transactions.forEach(t => {
          if (t.mga_id == '' || t.mga_id == null || t.mga_name == '' || t.mga_name == null) {
            mgaMissingIDCount++;
          } else {
            if (!this.agencyToAgencyIdMappingTable.has(t.mga_id)) {
              missingMGAList.set(t.mga_id, t.mga_name);
            }
          }

          if (t.carrier_name == '' || t.carrier_name == null || t.recruiter == '' || t.recruiter == null) {
            carrierMissingIDCount++;
          }

          if (t.agent_id == '' || t.agent_id == null) {
            agentMissingIDCount++;
          }

          if (!isMatch(t.transdate, 'M/d/yyyy')) {
            incorrectDateFormatCount++;
          }
        });

        if (incorrectDateFormatCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            "FATAL ERROR: Can't Import: Transdate is incorrectly formatted in " + incorrectDateFormatCount + ' records',
          );
          messages.unshift('========================================================');
          return false;
        }

        if (carrierMissingIDCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            "FATAL ERROR: Can't Import: Import File is missing either a Carrier Name or a Carrier ID in " +
              carrierMissingIDCount +
              ' records',
          );
          messages.unshift('========================================================');
          return false;
        }

        if (mgaMissingIDCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            "FATAL ERROR: Can't Import: Import File is missing either a MGA Name or a MGA ID in " +
              mgaMissingIDCount +
              ' records.',
          );
          messages.unshift('========================================================');
          return false;
        }

        if (missingMGAList.size > 0) {
          missingMGAList.forEach((value, key) => {
            messages.unshift('Import File contains unknown MGA Id: ' + value + '(' + key + ').');
          });
          messages.unshift('========================================================');
          messages.unshift('FATAL ERROR: Import File contains ' + missingMGAList.size + ' unknown MGA.');
          messages.unshift('========================================================');
          return false;
        }

        if (agentMissingIDCount > 0) {
          messages.unshift(
            'Import File is missing an Agent ID for ' +
              agentMissingIDCount +
              " records. Establishing Temp Id's for them.",
          );
        }

        messages.unshift('========================================================');
        messages.unshift('Validation Success.');
        messages.unshift('========================================================');

        return true;
      },
      err => {
        console.error('Error in Report Admin', err);
        return false;
      },
    );
  }
}
