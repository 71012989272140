import { Injectable } from '@angular/core';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { AgentService } from '../../../../services/agent.service/agent.service';
import { BaseFormService } from '../../../../../../../../../ag-common-svc/projects/ag-common-svc/src/lib/utils/base-form-service';
import { Agent, AgentCampaigns, AgentCampaignsKeys, AgentKeys, BaseModelKeys } from '@ag-common-lib/public-api';
import { pick } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CampaignsService extends BaseFormService<AgentCampaigns> {
  formData: AgentCampaigns;

  private _agentId: string;
  private _agentCampaigns: AgentCampaigns;
  constructor(private agentService: AgentService, private readonly toastr: ToastrService) {
    super();
  }

  save = async (modalWindowComponent: ModalWindowComponent) => {
    const hasChanges = this.formChangesDetector.hasChanges;

    if (hasChanges) {
      const agentId = this._agentId;
      const updates = {};
      const changes = this.formChangesDetector.getAllChanges();

      changes.forEach(([key]) => {
        const update = this.formData[key] ?? null;
        Object.assign(updates, { [key]: update });
      });

      this.startProgress();
      this.agentService
        .updateAgentFields(agentId, { [AgentKeys.campaigns]: Object.assign(this._agentCampaigns, updates) })
        .then(() => {
          this.toastr.success('Agent Campaigns Successfully Updated!');
          this.formChangesDetector.clear();
          modalWindowComponent?.hideModal();
        })
        .catch(e => {
          this.toastr.error('Agent Campaigns Update Failed!');
          throw new Error();
        })
        .finally(() => {
          this.stopProgress();
        });

      return { agentId, [AgentKeys.campaigns]: updates };
    }

    return null;
  };

  public getFormData = (agent?: Partial<Agent>) => {
    this._agentId = agent[BaseModelKeys.dbId];
    this._agentCampaigns = agent[AgentKeys.campaigns];
    const initialData = pick(agent[AgentKeys.campaigns], [
      AgentCampaignsKeys.outstandSite,
      AgentCampaignsKeys.outstandContacts,
      AgentCampaignsKeys.outstandSubscriptionLevel,
      AgentCampaignsKeys.outstandSubscriptionLevel,
      AgentCampaignsKeys.userSince,
      AgentCampaignsKeys.campaignsStatus,
      AgentCampaignsKeys.originalOutstandUser,
      AgentCampaignsKeys.stoppedCampaigns,
    ]);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);
        switch (prop) {
          case AgentCampaignsKeys.originalOutstandUser:
            if (!value) {
              Object.assign(this.formData, { [AgentCampaignsKeys.outstandSubscriptionLevel]: null });
              Object.assign(this.formData, { [AgentCampaignsKeys.outstandContacts]: null });
              Object.assign(this.formData, { [AgentCampaignsKeys.outstandSite]: null });
              Object.assign(this.formData, { [AgentCampaignsKeys.outstandEmailsSent]: null });
            }
            break;

          default:
            break;
        }
        return true;
      },
    });

    return this.formData;
  };
}
