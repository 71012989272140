import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AgentKeys,
  Constants,
  Messages,
  PhoneNumber,
  PhoneNumberType,
  PhoneNumberTypeDescriptionMap,
  PHONE_NUMBER_TYPE_LOOKUP,
} from '@ag-common-lib/public-api';
import { ToastrService } from 'ngx-toastr';
import { AgentService } from '../../../../services/agent.service/agent.service';
import { BehaviorSubject } from 'rxjs';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';

@Component({
  selector: 'ag-shr-phone-numbers',
  templateUrl: './phone-numbers.component.html',
  styleUrls: ['./phone-numbers.component.scss'],
})
export class PhoneNumbersComponent {
  @Input() agentId: string;
  @Input() phoneNumbers: PhoneNumber[] = [];
  @Input() canEdit = true;
  @Input() canCreate = true;
  @Input() canDelete = true;
  @Output() phoneNumbersChange = new EventEmitter();

  @ViewChild('phoneNumbersEditorModalRef', { static: true }) phoneNumbersEditorModalComponent: ModalWindowComponent;

  readonly inProgress$ = new BehaviorSubject<boolean>(false);
  readonly AgentKeys = AgentKeys;
  readonly PHONE_NUMBER_TYPE_LOOKUP = PHONE_NUMBER_TYPE_LOOKUP;
  readonly PhoneNumberTypeDescriptionMap = PhoneNumberTypeDescriptionMap;
  readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  readonly phoneMask: string = Constants.PHONE_MASK;
  readonly Messages = Messages;
  protected readonly emptyMessage = 'No Phone Numbers Currently Exist';

  constructor(private toastrService: ToastrService, private agentService: AgentService) {}

  showPhoneNumbersEditorModal = (): void => {
    this.phoneNumbersEditorModalComponent.showModal();
  };

  onEditorPreparing = e => {
    this.inProgress$.next(false);
    if (e.parentType !== 'dataRow') {
      return;
    }

    if (e.dataField === 'is_primary') {
      e.editorOptions.disabled = e.row.data.is_primary;
    }
  };

  onInitNewRow = e => {
    e.data.is_primary = !this.phoneNumbers?.length;
    e.data.is_whatsapp = false;
    e.data.phone_type = PhoneNumberType.Mobile;
  };

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;

    const isUniq = this.checkIsPhoneNumberUniq(data);

    if (!isUniq) {
      this.toastrService.error('Same Phone Number already exists in this profile');

      e.cancel = true;
      return;
    }

    const phoneNumbers = this.normalizePhoneNumbers(data);

    phoneNumbers.push(Object.assign({ id: key }, data));

    e.cancel = this.updatePhoneNumbers(phoneNumbers);
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);

    const isUniq = this.checkIsPhoneNumberUniq(data, e?.key);

    if (!isUniq) {
      this.toastrService.error('Same Phone Number already exists in this profile');

      e.cancel = true;
      return;
    }

    const phoneNumbers = this.normalizePhoneNumbers(data, e?.key);

    e.cancel = this.updatePhoneNumbers(phoneNumbers);
  };

  onRowRemoving = e => {
    const phoneNumbers = this.phoneNumbers.filter(address => {
      return address !== e.key;
    });

    e.cancel = this.updatePhoneNumbers(phoneNumbers);
  };

  canDeleteRow = e => !e.row.data.is_primary;

  private checkIsPhoneNumberUniq = (data, key?: PhoneNumber) => {
    return this.phoneNumbers.every(phoneNumber => {
      if (key && phoneNumber === key) {
        return true;
      }

      return data?.number !== phoneNumber?.number;
    });
  };

  private normalizePhoneNumbers = (data, key?: PhoneNumber) => {
    const isPrimary = data?.is_primary;

    return this.phoneNumbers.map(phoneNumber => {
      if (key && phoneNumber === key) {
        return data;
      }

      const normalizedPhoneNumber = Object.assign({}, phoneNumber);
      if (isPrimary) {
        Object.assign(normalizedPhoneNumber, { is_primary: false });
      }

      return normalizedPhoneNumber;
    });
  };

  private updatePhoneNumbers = phoneNumbers => {
    this.inProgress$.next(true);
    return this.agentService
      .updateAgentFields(this.agentId, { [AgentKeys.phone_numbers]: phoneNumbers })
      .then(() => {
        this.phoneNumbers = phoneNumbers;
        this.phoneNumbersChange.emit(phoneNumbers);
      })
      .finally((): void => {
        this.inProgress$.next(false);
      });
  };
}
