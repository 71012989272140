import { Component, HostBinding } from '@angular/core';
import { ConferenceModalService } from '../conference-form.service';
import { map } from 'rxjs';

@Component({
  selector: 'ag-crm-conference-embedded-form',
  templateUrl: './conference-embedded-form.component.html',
  styleUrls: ['./conference-embedded-form.component.scss'],
})
export class ConferenceEmbeddedFormComponent {
  @HostBinding('class') className = 'conference-embedded-form';

  protected src$ = this.conferenceModalService.conferenceDbId$.pipe(
    map(conferenceDbId => {
      return `https://alliance-group-poc-portal.firebaseapp.com/events-inquiry/${conferenceDbId}`;
    }),
  );
  protected iframeData$ = this.conferenceModalService.conferenceDbId$.pipe(
    map(conferenceDbId => {
      return `
        <iframe
          id="agCampaignServicesListIframe"
          src="https://alliance-group-poc-portal.firebaseapp.com/events-inquiry/${conferenceDbId}"
          style="width: 100%; border: none; min-height: 356px;"
        >
        </iframe>

        <script>
          let agCampaignServicesListIframe = window.document.getElementById('agCampaignServicesListIframe');

          window.addEventListener('load', () => {
            agCampaignServicesListIframe = window.document.getElementById('agCampaignServicesListIframe');
          })

          window.addEventListener('message', message => {
          if (message.data.type !== 'ag-campaign-services-list-resize') {
            return;
          }

          if (!agCampaignServicesListIframe) {
            agCampaignServicesListIframe = window.document.getElementById('agCampaignServicesListIframe');
          }

            const height = message.data.height;

            agCampaignServicesListIframe.style.height = height + 'px';
          });
        </script>
      `;
    }),
  );

  constructor(private conferenceModalService: ConferenceModalService) {
    // let agCampaignServicesListIframe = window.document.getElementById('agCampaignServicesListIframe');
    // window.addEventListener('load', () => {
    //   debugger;
    //   agCampaignServicesListIframe = window.document.getElementById('agCampaignServicesListIframe');
    // });
    // window.addEventListener('message', message => {
    //   if (message.data.type !== 'ag-campaign-services-list-resize') {
    //     return;
    //   }
    //   if (!agCampaignServicesListIframe) {
    //     agCampaignServicesListIframe = window.document.getElementById('agCampaignServicesListIframe');
    //   }
    //   const height = message.data.height;
    //   agCampaignServicesListIframe.style.height = height + 'px';
    // });
  }
}
