<div class="report-administration__container">
  <div class="row mt-2">
    <div class="col-6">
      <div class="dx-fieldset-header">Upload Paid File</div>
    </div>
    <div class="col-6">
      <dx-button
        style="float: right"
        stylingMode="contained"
        text="Show Upload History"
        type="normal"
        [width]="240"
        (onClick)="showUploadHistory()"
      >
      </dx-button>
    </div>
  </div>

  <div class="report-administration__work-area row mt-2">
    <div class="col-6" id="upload">
      <div class="form">
        <div class="report-administration__item-row">
          <div class="report-administration__item-label"><h3>Year</h3></div>
          <div class="report-administration__item-input">
            <dx-select-box
              [label]="'Year'"
              [labelMode]="'hidden'"
              [value]="selectedYear$ | async"
              (valueChange)="handleYearChange($event)"
              [items]="years"
              width="150"
            ></dx-select-box>
          </div>
        </div>

        <div class="report-administration__item-row">
          <div class="report-administration__item-label"><h3>"As Of" Date</h3></div>
          <div class="report-administration__item-input">
            <dx-date-box [label]="asOfLabel" [labelMode]="'hidden'" [(value)]="asOfDate" width="150"></dx-date-box>
          </div>
        </div>

        <div class="report-administration__item-row">
          <div class="report-administration__item-label">
            <h3>
              Transaction to Import: <span> ({{ transactions?.length ?? 0 }}) </span>
            </h3>
          </div>
        </div>

        <div class="report-administration__item-row">
          <div class="report-administration__item-label">
            <h3>
              Summaries to Import: <span> ({{ summaries?.length ?? 0 }}) </span>
            </h3>
          </div>
        </div>
        <div class="report-administration__item-row">
          <div id="fileuploader-container">
            <dx-file-uploader
              class="report-administration__file-uploader"
              selectButtonText="Select File"
              accept=".csv"
              [uploadFile]="onFileUploaded.bind(this)"
              [multiple]="false"
              name="files[]"
              [allowCanceling]="true"
              [showFileList]="true"
            >
            </dx-file-uploader>
          </div>
        </div>
      </div>

      <div class="mt-4" *ngIf="showImportButton">
        <shr-button-with-indicator
          [title]="'Import'"
          (onClick)="handleImport()"
          [isInProgress]="importInProgress"
        ></shr-button-with-indicator>
      </div>
    </div>

    <div class="report-administration__messages-container col-6">
      <h1>Messages</h1>

      <hr style="width: 100%" />

      <div class="report-administration__messages">
        <dx-scroll-view [useNative]="false" [showScrollbar]="'always'" width="100%" height="100%">
          <ng-container *ngFor="let msg of messages; let i = index">
            <h4 class="report-administration__message">{{ msg }}</h4>
          </ng-container>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [width]="900"
  [height]="500"
  [showTitle]="true"
  title="Upload History"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="displayUploadHistory"
>
  <div id="data-grid-demo">
    <dx-data-grid #reportsTable [dataSource]="dataSource" [showBorders]="true" [renderAsync]="true">
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxi-column dataField="fileName"></dxi-column>
      <dxi-column dataField="asOfDate" [width]="150"></dxi-column>
      <dxi-column dataField="year"></dxi-column>
      <dxi-column
        [dataField]="BaseModelKeys.createdByEmail"
        [calculateCellValue]="calculateCreatedByCellValue"
        [width]="150"
      ></dxi-column>
      <dxi-column
        [dataField]="BaseModelKeys.createdDate"
        [calculateCellValue]="calculateCreatedDateCellValue"
        [width]="200"
        [sortIndex]="0"
        [sortOrder]="'desc'"
        [dataType]="'datetime'"
      ></dxi-column>

      <dxi-column dataField="recordsImported" caption="Records" [width]="75"></dxi-column>
      <dxi-column dataField="summariesGenerated" caption="Summaries" [width]="100"></dxi-column>

      <dxi-column
        [dataField]="'sourceFileKey'"
        caption="Source File"
        cellTemplate="sourceFileKeyCellTemplate"
      ></dxi-column>

      <div *dxTemplate="let cell of 'sourceFileKeyCellTemplate'">
        <a *ngIf="cell.value" [href]="cell.value | wasabiFile">Download</a>
      </div>
    </dx-data-grid>
  </div>

  <div class="row d-flex m-3 config-buttons">
    <button class="btn btn-primary" (click)="closeUploadHistory()">Close</button>
  </div>
</dx-popup>
