// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  common: {
    apiKey: 'AIzaSyBQk0mf1jUDYzgdZrOoKi7TT9cINP-ttn0',
    authDomain: 'alliance-group-portal.firebaseapp.com',
    projectId: 'alliance-group-portal',
    storageBucket: 'alliance-group-portal.appspot.com',
    messagingSenderId: '271585780604',
    appId: '1:271585780604:web:bfa92dc406920565befaf9',
    measurementId: 'G-H76S2T9L7G',
  },
  google: {
    mapsAPIKey: 'AIzaSyCrFVl8PrFGe10YSFCBr9WDBtdEKb1A7LE',
    mapId: '56461284e4d20403',
  },
  domain: 'dev-arm.alliancegrouplife.com',
  agentPortalUrl: 'https://dev.alliancegrouplife.com',
  session_expires: 120,
  stripe_payments_public_key:
    'pk_test_51MON3hBZ6K5GAvsGVuCE875tXoedNEHv3TKS9okIkxKUDh0ML3iusdx76etQ5G5J3nMbyNQC1M28e8bSBRepNhMs00vcjKL5K2',
  user_admin_url: 'https://us-central1-alliance-group-portal.cloudfunctions.net/users',
  devextremeLicenseKey:
    'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjQzNTMxYTU3LWQxNTMtNGRiYS1hMjZjLTdlMjE1OTc0MWM3YiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.QoiYKRdma2s+Ylj9zHLLWvDigChChRjHeqNv1AD0TV7er3xxoBR52uDeT++5z0zNbqwWMBrlM/DTd9va/tVbzZsFIHHPQtAWjg4ruc0f2swWcHy4ZMx5A5f3nKjhtJcQrOBrcQ==',
};
