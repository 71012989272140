import { Injectable } from '@angular/core';
import {
  Address,
  BaseModelKeys,
  Conference,
  ConferenceKeys,
  ConferenceStepsTemplateArgumentKey,
  ConferenceStepsTemplateArgumentName,
} from 'ag-common-lib/public-api';
import { BaseFormService, ConferenceService } from 'ag-common-svc/public-api';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class ConferenceModalService extends BaseFormService<Conference> {
  conferenceStepsTemplateArguments$ = new BehaviorSubject(null);
  private _conferenceDbId$ = new BehaviorSubject(null);
  conferenceDbId$ = this._conferenceDbId$.asObservable();

  constructor(
    private conferenceService: ConferenceService,
    private toastrService: ToastrService,
    private currencyPipe: CurrencyPipe,
  ) {
    super();
  }

  getFormData = async (conference?: Partial<Conference>): Promise<Conference> => {
    const conferenceDbId = conference?.[BaseModelKeys.dbId] ?? this.conferenceService?.getDocDbId();
    this._conferenceDbId$.next(conferenceDbId);

    const initialData = Object.assign({}, new Conference(), conference);

    const conferenceStepsTemplateArguments = [
      {
        [ConferenceStepsTemplateArgumentKey.id]: ConferenceStepsTemplateArgumentName.excursionBudget,
        [ConferenceStepsTemplateArgumentKey.value]: this.currencyPipe.transform(conference?.excursions_budget),
      },
      {
        [ConferenceStepsTemplateArgumentKey.id]: ConferenceStepsTemplateArgumentName.flightAgentName,
        [ConferenceStepsTemplateArgumentKey.value]: conference?.flightAgentName,
      },
    ];
    this.conferenceStepsTemplateArguments$.next(conferenceStepsTemplateArguments);

    if (conference?.[ConferenceKeys.hotelEnabled]) {
      Object.assign(initialData, {
        [ConferenceKeys.hotelAddress]: this.prepareAddressProxy(initialData?.[ConferenceKeys.hotelAddress]),
      });
    }

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        switch (prop) {
          case ConferenceKeys.eventEnabled:
            if (!value) {
              Reflect.set(target, ConferenceKeys.eventActive, false, receiver);
            }
            break;
          case ConferenceKeys.hotelEnabled:
            if (!value) {
              Object.assign(this.formData, {
                [ConferenceKeys.hotelAddress]: null,
                [ConferenceKeys.hotelActive]: false,
              });
            }
            if (value) {
              Object.assign(this.formData, { [ConferenceKeys.hotelAddress]: this.prepareAddressProxy() });
            }
            break;
          case ConferenceKeys.excursionsEnabled:
            if (!value) {
              Reflect.set(target, ConferenceKeys.excursionsActive, false, receiver);
            }
            break;
          case ConferenceKeys.guestsEnabled:
            if (!value) {
              Reflect.set(target, ConferenceKeys.guestsActive, false, receiver);
            }
            break;

          default:
            break;
        }

        return true;
      },
    });

    return this.formData;
  };

  private prepareAddressProxy = (address?: Partial<Address>) => {
    return new Proxy(Object.assign({}, new Address(), address), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([ConferenceKeys.hotelAddress, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };

  updateConference(documentId: string, conference: Conference): Promise<Conference> {
    this.startProgress();
    return this.conferenceService
      .updateFields(documentId, conference)
      .then(() => {
        this.toastrService.success('Event Information Successfully Updated');
        this.onSuccessfulUpdated(Object.keys(conference));
        return conference;
      })
      .catch(err => {
        this.toastrService.error('Event Information was Not Updated');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  createConference(conference: Conference): Promise<Conference> {
    this.startProgress();
    const conferenceDbId = this._conferenceDbId$.value;

    return this.conferenceService
      .create(conference, conferenceDbId)
      .then(() => {
        this.toastrService.success('Event Information Successfully Created');
        this.onSuccessfulCreated();
        return conference;
      })
      .catch(err => {
        this.toastrService.error('Event Information was Not Created');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }
}
